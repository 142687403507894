import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function Home() {
  return (
    <div>
      <h1 style={{textAlign:'center'}}>Welcome to the Gadget Zone..</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'25px' }}>
        <Spinner animation="grow" />
      </div>
    </div>
  )
};