import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

const JSONFormatter = () => {
  const [inputJSON, setInputJSON] = useState('');
  const [formattedJSON, setFormattedJSON] = useState('');
  const [error, setError] = useState('');
  const [result, setResult] = useState('');

  const handleFormat = () => {
    try {
      // Parse and stringify JSON to format it with indentation
      const parsed = JSON.parse(inputJSON);
      const formatted = JSON.stringify(parsed, null, 2);
      setFormattedJSON(formatted);
      setError(''); // Clear any previous errors
      setResult(formatted);
    } catch (e) {
      setFormattedJSON('');
      setError(`Invalid JSON: ${e.message}`);
    }
  };

  const handleInputChange = (e) => {
    setInputJSON(e.target.value);
    setFormattedJSON(''); // Clear formatted output when input changes
    setError('');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(result);    
  };

  return (
    <div  className="tool-kit">
      <h2>JSON Formatter/Validator</h2>
      <textarea
        placeholder="Paste your JSON here..."
        value={inputJSON}
        onChange={handleInputChange}
        style={{ width: '100%', height: '250px', padding: '10px', fontFamily: 'monospace' }}
      />
      <Button variant="primary" onClick={handleFormat} style={{ marginTop: '10px', padding: '10px 20px', cursor: 'pointer' }}>
        Format JSON
      </Button>
      {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
      {formattedJSON && (
        <div style={{ marginTop: '10px' }}>
          <h3>Formatted JSON</h3>
          <pre value={result} style={{ background: '#f4f4f4', padding: '10px', borderRadius: '5px' }}>{formattedJSON}</pre>
          <Button variant="success" onClick={copyToClipboard} className="copy-button">Copy to Clipboard</Button>
        </div>
      )}
    </div>
  );
};

export default JSONFormatter;