import React, { useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/webpack'; // Ensure webpack is configured correctly
import Button from 'react-bootstrap/Button';

// Set worker path
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PdfToTextConverter = () => {
  const [textContent, setTextContent] = useState('');
  const [error, setError] = useState('');
  const [PDFContect, setResult] = useState('');

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file || file.type !== 'application/pdf') {
      setError('Please upload a valid PDF file.');
      return;
    }
    setError('');

    const reader = new FileReader();
    reader.onload = async () => {
      const typedArray = new Uint8Array(reader.result);
      try {
        const pdfDoc = await pdfjsLib.getDocument(typedArray).promise;
        let fullText = '';

        // Loop through each page and extract text
        for (let i = 1; i <= pdfDoc.numPages; i++) {
          const page = await pdfDoc.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map((item) => item.str).join(' ');
          fullText += `\n\nPage ${i}\n${pageText}`;
        }

        setTextContent(fullText);
        setResult(fullText);
      } catch (err) {
        setError('Failed to extract text from the PDF.');
        console.error(err);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(PDFContect);    
  };

  return (
    <div className="tool-kit">
      <h2>Upload a PDF and Extract Text</h2>
      <input type="file" accept="application/pdf" onChange={handleFileUpload} />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Button variant="primary" onClick={() => setTextContent('')}
        style={{ marginBottom:15 }}
        >Clear Text</Button><br/>
      <textarea
        value={textContent}
        readOnly
        rows="10"
        className="text-area"
        placeholder="Text will appear here after PDF is uploaded."
        style={{ width: '100%',
          padding: '10',
          marginTop: '10',
          fontSize: '16',
          border: '1 solid #ccc',
          borderRadius: 5}}
      ></textarea><br/>
      <Button variant="success" style={ {marginTop:15 }} onClick={copyToClipboard}>Copy to Clipboard</Button>
    </div>
  );
};

export default PdfToTextConverter;