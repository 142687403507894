import React, { useState } from 'react';
import { XMLParser, XMLBuilder } from 'fast-xml-parser';
import Button from 'react-bootstrap/Button';

const XMLFormatterValidator = () => {
  const [xmlInput, setXmlInput] = useState('');
  const [formattedXml, setFormattedXml] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [XMLResult, setResult] = useState('');

  const formatXml = () => {
    try {
      const parser = new XMLParser();
      const jsonObj = parser.parse(xmlInput);  // Parse XML to JSON
      
      const builder = new XMLBuilder({ format: true, ignoreAttributes: false });
      const prettyXml = builder.build(jsonObj); // Build XML from JSON with formatting
      
      setFormattedXml(prettyXml);
      setValidationMessage('XML is valid and formatted.');
      setResult(prettyXml);
    } catch (error) {
      setFormattedXml('');
      setValidationMessage(`Error: Invalid XML - ${error.message}`);
    }
  };

  const handleInputChange = (e) => {
    setXmlInput(e.target.value);
    setFormattedXml('');
    setValidationMessage('');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(XMLResult);    
  };

  return (
    <div className="tool-kit">
      <h2>XML Formatter and Validator</h2>
      <textarea
        value={xmlInput}
        onChange={handleInputChange}
        placeholder="Enter XML content here..."
        rows="10"
        style={{ width: '100%', fontSize: '16px', padding: '10px' }}
      />
      <br />
      <Button variant="primary" onClick={formatXml} style={{ marginTop: '10px', padding: '10px 20px', fontSize: '16px' }}>
        Format and Validate XML
      </Button>
      {validationMessage && <p style={{ color: validationMessage.startsWith('Error') ? 'red' : 'green' }}>{validationMessage}</p>}
      {formattedXml && (
        <pre
          value={XMLResult}  
          style={{
              backgroundColor: '#f4f4f4',
              padding: '10px',
              textAlign: 'left',
              marginTop: '20px',
              overflowX: 'auto',
              whiteSpace: 'pre-wrap',
            }}
        >
          {formattedXml}
        </pre>
      )}
      {formattedXml && (
        <Button variant="success" onClick={copyToClipboard} >Copy to Clipboard</Button>
      )}
    </div>
  );
};

export default XMLFormatterValidator;