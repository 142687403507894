
import React from 'react'

export default function Footer() {
  return (
    <footer>
      <p>© 2024 Gadget Zone. All rights reserved.</p>
    </footer>
  )
}
