import React, { useState } from 'react';

const ColorPickerConverter = () => {
  const [color, setColor] = useState('#ff0000');
  
  // Function to convert hex to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  };

  // Function to convert hex to HSL
  const hexToHsl = (hex) => {
    let r = parseInt(hex.slice(1, 3), 16) / 255;
    let g = parseInt(hex.slice(3, 5), 16) / 255;
    let b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: h = 0; break;
      }
      h /= 6;
    }

    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);
    return `${h}°, ${s}%, ${l}%`;
  };

  return (
    <div className="tool-kit">
      <h2>Color Picker and Converter</h2>
      
      <input
        type="color"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        style={{ width: '100px', height: '50px', cursor: 'pointer' }}
      />

      <div style={{ marginTop: '20px', fontSize: '18px' }}>
        <p><strong>Hex:</strong> {color}</p>
        <p><strong>RGB:</strong> {hexToRgb(color)}</p>
        <p><strong>HSL:</strong> {hexToHsl(color)}</p>
      </div>
    </div>
  );
};

export default ColorPickerConverter;