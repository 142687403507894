import React, { useState } from 'react';
import Tesseract from 'tesseract.js';

import '../Style/ImageToText.css';

const ImageToText = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [ocrResult, setOcrResult] = useState('');
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleExtractText = () => {
    if (selectedImage) {
      setLoading(true);
      Tesseract.recognize(selectedImage, 'eng', {
        logger: (m) => console.log(m),
      })
        .then(({ data: { text } }) => {
          setOcrResult(text);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

return (
    <div className="image-to-text-container">
      <h2>Upload an Image and Extract Text</h2>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {selectedImage && (
        <div className="image-preview">
          <img src={selectedImage} alt="Selected" width="300px" />
        </div>
      )}
      <button className="ocr-button" onClick={handleExtractText} disabled={loading}>
        {loading ? 'Extracting Text...' : 'Extract Text from Image'}
      </button>

      {ocrResult && (
        <div className="ocr-result">
          <h3>Extracted Text:</h3>
          <p>{ocrResult}</p>
        </div>
      )}
    </div>
  );
};


export default ImageToText;
