import React, { useState } from 'react';
import '../Style/LineBreakRemover.css';
import Button from 'react-bootstrap/Button';

const LineBreakRemover = () => {
  const [text, setText] = useState('');
  const [result, setResult] = useState('');
  const [preserveParagraphs, setPreserveParagraphs] = useState(true);

  const removeLineBreaks = () => {
    let processedText;
    if (preserveParagraphs) {
      processedText = text
        .replace(/\n\s*\n/g, '<<PARAGRAPH>>')  // Temporarily replace double line breaks
        .replace(/\s*\n\s*/g, ' ')             // Replace single line breaks with a single space
        .replace(/<<PARAGRAPH>>/g, '\n\n')     // Restore double line breaks for paragraphs
        .trim(); 
    } else {
      processedText = text.replace(/\s+/g, ' ').trim();
    }
    setResult(processedText);
  };

  const resetText = () => {
    setText('');
    setResult('');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(result);    
  };

  return (
    <div className="tool-kit line-break-remover">
      <h1>Remove Line Break</h1>
      <p >Choose one of these line break options:</p>
      <div>
        <input
          type="radio"
          id="preserveParagraphs"
          name="lineBreakOption"
          checked={preserveParagraphs}
          onChange={() => setPreserveParagraphs(true)}
        />
        <label htmlFor="preserveParagraphs">Remove some line breaks (preserve paragraphs)</label>
        <br />
        <input
          type="radio"
          id="removeAll"
          name="lineBreakOption"
          checked={!preserveParagraphs}
          onChange={() => setPreserveParagraphs(false)}
        />
        <label htmlFor="removeAll">Remove ALL line breaks</label>
      </div>

      <textarea
        placeholder="Paste your text here..."
        value={text}
        onChange={(e) => setText(e.target.value)}
        rows="6"
        className="text-area"
      ></textarea>

      <div>
        <Button variant="primary" onClick={removeLineBreaks}>Remove Line Breaks</Button>
        <Button variant="dark" onClick={resetText}>Reset</Button>
      </div>

      <h2>New Text without Line Breaks</h2>
      <textarea
        value={result}
        readOnly
        rows="6"
        className="text-area"
        placeholder="Your modified text will appear here..."
      ></textarea>

      <Button variant="success" onClick={copyToClipboard}>
        Copy to Clipboard
      </Button>
    </div>
  );
};

export default LineBreakRemover;
