import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

const RegexTester = () => {
  const [regexInput, setRegexInput] = useState('');
  const [textInput, setTextInput] = useState('');
  const [matches, setMatches] = useState([]);
  const [error, setError] = useState('');

  const handleTestRegex = () => {
    setMatches([]);
    setError('');

    try {
      const regex = new RegExp(regexInput, 'g'); // Global flag to find all matches
      const matchArray = [];
      let match;

      while ((match = regex.exec(textInput)) !== null) {
        matchArray.push({
          match: match[0],
          index: match.index,
          groups: match.slice(1),
        });
      }

      setMatches(matchArray);
    } catch (e) {
      setError('Invalid regular expression');
    }
  };

  const highlightMatches = () => {
    if (matches.length === 0) return { __html: textInput }; // Returning the plain text if no matches
  
    let highlightedText = '';
    let lastIndex = 0;
  
    matches.forEach(({ match, index }) => {
      highlightedText += textInput.slice(lastIndex, index);
      highlightedText += `<span style="background-color: yellow; font-weight: bold;">${match}</span>`;
      lastIndex = index + match.length;
    });
  
    highlightedText += textInput.slice(lastIndex);
  
    return { __html: highlightedText };
  };

  return (
    <div className="tool-kit">
      <h2>Regex Tester</h2>
      <input
        type="text"
        placeholder="Enter regular expression..."
        value={regexInput}
        onChange={(e) => setRegexInput(e.target.value)}
        style={{ width: '100%', padding: '10px', fontSize: '16px', marginBottom: '10px' }}
      />
      <textarea
        placeholder="Enter text to test..."
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
        rows="6"
        style={{ width: '100%', padding: '10px', fontSize: '16px', marginBottom: '10px' }}
      />
      <Button variant="primary" onClick={handleTestRegex} style={{ padding: '10px 20px', fontSize: '16px', marginBottom: '20px' }}>
        Test Regex
      </Button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div style={{ textAlign: 'left' }}>
        <h3>Matched Results:</h3>
        {matches.length > 0 ? (
          matches.map((match, idx) => (
            <div key={idx}>
              <strong>Match {idx + 1}: </strong>
              {match.match} (at index {match.index})
              {match.groups.length > 0 && (
                <div>
                  <strong>Groups:</strong>
                  <ul>
                    {match.groups.map((group, i) => (
                      <li key={i}>Group {i + 1}: {group}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No matches found.</p>
        )}
      </div>
      <h3>Highlighted Matches in Text:</h3>
      <div
        style={{
            whiteSpace: 'pre-wrap',
            backgroundColor: '#f4f4f4',
            padding: '10px',
            borderRadius: '5px',
            textAlign: 'left',
        }}
        dangerouslySetInnerHTML={highlightMatches()}
        />
    </div>
  );
};

export default RegexTester;
