import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, Routes, Route } from "react-router-dom";
import LineBreakRemover from "../pages/LineBreakRemover";
import ImageToText from '../pages/ImageToText';
import JSONFormatter from '../pages/JSONFormatter';
import ColorPickerConverter from '../pages/ColorPickerConverter';
import XMLFormatterValidator from '../pages/XMLFormatterValidator';
import PdfToTextConverter from '../pages/PdfToTextConverter';
import RegexTester from '../pages/RegexTester';

import { useState } from "react";

function ToolBoxNavbar() {

  const [selectedKey, setSelectedKey] = useState("1");

  const handleSelect = (eventKey) =>{
    setSelectedKey(eventKey);
  };

  return (
    <div>
      {/* Second Navbar */}
      <Nav variant="pills" activeKey={selectedKey} className="mb-3" onSelect={handleSelect}>
        <Nav.Item>
          <Nav.Link as={Link} to="/tool-box/remove-line-breaks" eventKey="1">
            Remove Line Breaks
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/tool-box/image-to-text" eventKey="2" onSelect={handleSelect}>
            Image To Text
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/tool-box/color-picker" eventKey="3">
            Color Picker
          </Nav.Link>
        </Nav.Item>
        <NavDropdown title="JSON" id="nav-dropdown">
          <NavDropdown.Item as={Link} to="/tool-box/json-formatter" eventKey="4.1">
            JSON Formatter/Validator
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="XML" id="nav-dropdown">
          <NavDropdown.Item as={Link} to="/tool-box/xml-formatter" eventKey="5.1">
            XML Formatter/Validator
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="PDF" id="nav-dropdown">
          <NavDropdown.Item as={Link} to="/tool-box/pdf-to-text" eventKey="6.1">
            PDF to Text Converter
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Item>
          <Nav.Link as={Link} to="/tool-box/regix-tester" eventKey="7.1">
            Regex Tester
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {/* Nested Routes for Tool Box */}
      <div className="toolbox-content">
        <Routes>
          <Route path="remove-line-breaks" element={<LineBreakRemover />} />
          <Route path="image-to-text" element={<ImageToText/>} />
          <Route path="color-picker" element={<ColorPickerConverter/>} />
          <Route path="json-formatter" element={<JSONFormatter/>} />
          <Route path="xml-formatter" element={<XMLFormatterValidator/>} />
          <Route path="pdf-to-text" element={<PdfToTextConverter/>} />
          <Route path="regix-tester" element={<RegexTester/>} />
        </Routes>
      </div>
    </div>
  );
}

export default ToolBoxNavbar;
