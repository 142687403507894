//import React, { useState } from 'react';
import { BrowserRouter ,Route,Routes} from 'react-router-dom';
import MainNavBar from './components/MainNavBar';
import ToolBoxNavbar from './components/ToolBoxNavbar';
import Footer from './components/Footer';

import Home from './pages/Home';

import './App.css';

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        {/* Navbar Section */}
        <header>
          <MainNavBar />
        </header>
        
        {/* Main Content Area */}
        <div className="content-layout">
          {/* Left Ad Space */}
          <aside className="ad-space">
            <p></p>
          </aside>
          
          {/* Main Content */}
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/tool-box/*" element={<ToolBoxNavbar />} />
            </Routes>
          </main>

          {/* Right Ad Space */}
          <aside className="ad-space">
            <p></p>
          </aside>
        </div>

        {/* Footer Section */}
          <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;

